frappe.provide('frappe');


frappe.views.QueryReport = class CustomReport extends frappe.views.QueryReport {

    add_custom_filters(filters) {
        let custom_filters = [{
            "fieldname": "freeze",
            "label": "Freeze Column",
            "fieldtype": "Select",
            "placeholder": "Freeze Column",
            "input_class": "input-xs"
        }, {
            "fieldname": "grouping",
            "label": "Group By Column",
            "fieldtype": "Select",
            "placeholder": "Group By Column",
            "input_class": "input-xs"
        }, {
            "fieldname": "coloring",
            "label": "Color By Column",
            "fieldtype": "Button",
            "placeholder": "Color By Column",
            "input_class": "input-xs"
        }];
        custom_filters.forEach((e) => {
            if (!(filters.some(item => JSON.stringify(item.fieldname) === JSON.stringify(e.fieldname)))) {
                filters.push(e);
            };
        })
    }

    setup_filters() {
        this.clear_filters();
        const { filters = [] } = this.report_settings;

        this.add_custom_filters(filters);

        let filter_area = this.page.page_form;

        this.filters = filters
            .map((df) => {
                if (df.fieldtype === "Break") return;

                let f = this.page.add_field(df, filter_area);

                if (df.default) {
                    f.set_input(df.default);
                }

                if (df.get_query) f.get_query = df.get_query;
                if (df.on_change) f.on_change = df.on_change;

                df.onchange = () => {
                    this.refresh_filters_dependency();

                    let current_filters = this.get_filter_values();
                    if (
                        this.previous_filters &&
                        JSON.stringify(this.previous_filters) === JSON.stringify(current_filters)
                    ) {
                        // filter values have not changed
                        return;
                    }

                    // clear previous_filters after 10 seconds, to allow refresh for new data
                    this.previous_filters = current_filters;
                    setTimeout(() => (this.previous_filters = null), 10000);

                    if (f.on_change) {
                        f.on_change(this);
                    } else {
                        if (this.prepared_report) {
                            this.reset_report_view();
                        } else if (!this._no_refresh) {
                            this.refresh(true);
                        }
                    }
                };

                f = Object.assign(f, df);

                return f;
            })
            .filter(Boolean);

        this.refresh_filters_dependency();
        if (this.filters.length === 0) {
            // hide page form if no filters
            this.page.hide_form();
        } else {
            this.page.show_form();
        }
    }

    set_route_filters(route_options) {
        if (!route_options) route_options = frappe.route_options;

        if (route_options) {
            const fields = Object.keys(route_options);

            const filters_to_set = this.filters.filter((f) => fields.includes(f.df.fieldname));

            const promises = filters_to_set.map((f) => {
                return () => {
                    let value = route_options[f.df.fieldname];
                    if (typeof value === "string" && value[0] === "[") {
                        // multiselect array
                        value = JSON.parse(value);
                    }
                    f.set_value(value);
                };
            });
            promises.push(() => {
                frappe.route_options = null;
            });

            return frappe.run_serially(promises);
        }
    }


	get_menu_items() {
		let items = [
			{
				label: __("Refresh"),
				action: () => this.refresh(),
				class: "visible-xs",
			},
			{
				label: __("Edit"),
				action: () => frappe.set_route("Form", "Report", this.report_name),
				condition: () => frappe.user.is_report_manager(),
				standard: true,
			},
			{
				label: __("Print"),
				action: () => {
					let dialog = frappe.ui.get_print_settings(
						false,
						(print_settings) => this.print_report(print_settings),
						this.report_doc.letter_head,
						this.get_visible_columns()
					);
					this.add_portrait_warning(dialog);
				},
				condition: () => frappe.model.can_print(this.report_doc.ref_doctype),
				standard: true,
			},
			{
				label: __("PDF"),
				action: () => {
					let dialog = frappe.ui.get_print_settings(
						false,
						(print_settings) => this.pdf_report(print_settings),
						this.report_doc.letter_head,
						this.get_visible_columns()
					);

					this.add_portrait_warning(dialog);
				},
				condition: () => frappe.model.can_print(this.report_doc.ref_doctype),
				standard: true,
			},
			{
				label: __("Export"),
				action: () => this.export_report(),
				condition: () => frappe.model.can_export(this.report_doc.ref_doctype),
				standard: true,
			},
			{
				label: __("Setup Auto Email"),
				action: () =>
					frappe.set_route("List", "Auto Email Report", { report: this.report_name }),
				standard: true,
			},
			{
				label: __("Add Column"),
				action: () => {
					let d = new frappe.ui.Dialog({
						title: __("Add Column"),
						fields: [
							{
								fieldtype: "Select",
								fieldname: "doctype",
								label: __("From Document Type"),
								options: this.linked_doctypes.map((df) => ({
									label: df.doctype,
									value: df.doctype,
								})),
								change: () => {
									let doctype = d.get_value("doctype");
									frappe.model.with_doctype(doctype, () => {
										let options = frappe.meta
											.get_docfields(doctype)
											.filter(frappe.model.is_value_type)
											.map((df) => ({
												label: df.label,
												value: df.fieldname,
											}));
											
										d.set_df_property(
											"field",
											"options",
											options.sort(function (a, b) {
												if (a.label < b.label) {
													return -1;
												}
												if (a.label > b.label) {
													return 1;
												}
												return 0;
											})
										);
									});
								},
							},
							{
								fieldtype: "Select",
								label: __("Field"),
								fieldname: "field",
								options: [],
							},
							{
								fieldtype: "Select",
								label: __("Insert After"),
								fieldname: "insert_after",
								options: this.columns.map((df) => df.label),
							},
						],
						primary_action: (values) => {
							const custom_columns = [];
							let df = frappe.meta.get_docfield(values.doctype, values.field);
							const insert_after_index = this.columns.findIndex(
								(column) => column.label === values.insert_after
							);

							custom_columns.push({
								fieldname: this.columns
									.map((column) => column.fieldname)
									.includes(df.fieldname)
									? df.fieldname + "-" + frappe.scrub(values.doctype)
									: df.fieldname,
								fieldtype: df.fieldtype,
								label: df.label,
								insert_after_index: insert_after_index,
								link_field: this.doctype_field_map[values.doctype],
								doctype: values.doctype,
								options: df.options,
								width: 100,
							});

							this.custom_columns = this.custom_columns.concat(custom_columns);
							frappe.call({
								method: "frappe.desk.query_report.get_data_for_custom_field",
								args: {
									field: values.field,
									doctype: values.doctype,
									names: Array.from(
										this.doctype_field_map[values.doctype].names
									),
								},
								callback: (r) => {
									const custom_data = r.message;
									const link_field =
										this.doctype_field_map[values.doctype].fieldname;
									this.add_custom_column(
										custom_columns,
										custom_data,
										link_field,
										values,
										insert_after_index
									);
									d.hide();
								},
							});
							this.set_menu_items();
						},
					});

					d.show();
				},
				standard: true,
			},
			{
				label: __("User Permissions"),
				action: () =>
					frappe.set_route("List", "User Permission", {
						doctype: "Report",
						name: this.report_name,
					}),
				condition: () => frappe.user.has_role("System Manager"),
				standard: true,
			},
		];

		if (frappe.user.is_report_manager()) {
			items.push({
				label: __("Save"),
				action: () => {
					let d = new frappe.ui.Dialog({
						title: __("Save Report"),
						fields: [
							{
								fieldtype: "Data",
								fieldname: "report_name",
								label: __("Report Name"),
								default:
									this.report_doc.is_standard == "No" ? this.report_name : "",
								reqd: true,
							},
						],
						primary_action: (values) => {
							frappe.call({
								method: "frappe.desk.query_report.save_report",
								args: {
									reference_report: this.report_name,
									report_name: values.report_name,
									columns: this.get_visible_columns(),
									filters: this.get_filter_values(),
                                    //changes made here to get the data of custom_columns
									custom_columns: this.custom_columns || [] 
								},
								callback: function (r) {
									this.show_save = false;
									d.hide();
									frappe.set_route("query-report", r.message);
								},
							});
						},
					});
					d.show();
				},
				standard: true,
			});
		}

		return items;
	}

    add_filter_options(columns) {
        this.tabulator_cols = [];
        let all_labels = [""];
        columns.forEach((t) => {
            t.title = t.label;
            t.field = t.fieldname;
            this.tabulator_cols.push(t);
            all_labels.push(t.label);
            delete t.width;
        });
        // adding options for freezing
        cur_page.page.page.fields_dict.freeze.df.options = all_labels; 
        // in all_labels here data is not coming
        cur_page.page.page.fields_dict.freeze.refresh();
        // adding options for grouping
        cur_page.page.page.fields_dict.grouping.df.options = all_labels;
        cur_page.page.page.fields_dict.grouping.refresh();

        // adding dialog for coloring condition
        cur_page.page.page.fields_dict.coloring.onclick = () => {
            const dialog = new frappe.ui.Dialog({
                title: __("Apply Color"),
                fields: [{
                        fieldname: "color_field",
                        label: __("Field"),
                        fieldtype: "Select",
                        options: all_labels,
                        reqd: 1
                    },
                    {
                        fieldname: "sb_1",
                        label: __("Add Color Condition"),
                        fieldtype: "Section Break",
                    },
                    {
                        fieldname: "color_condition",
                        label: __("Condition"),
                        options: ["==", "!=", "<", ">", "<=", ">="],
                        fieldtype: "Select",
                        reqd: 1
                    },
                    {
                        fieldname: "cb_2",
                        fieldtype: "Column Break",
                    },
                    {
                        fieldname: "condition_value",
                        label: __("Value"),
                        fieldtype: "Data",
                        reqd: 1
                    },
                    {
                        fieldname: "cb_3",
                        fieldtype: "Column Break",
                    },
                    {
                        fieldname: "color",
                        label: __("Color"),
                        fieldtype: "Color",
                        reqd: 0
                    },
                ],
                primary_action_label: __("Apply Colors"),
                primary_action: (values) => {
                    cur_page.page.page.fields_dict.coloring.value = values.color_field + values.color_condition + values.condition_value + values.color;
                    cur_page.page.page.fields_dict.coloring.onchange();
                    dialog.hide();
                },
                secondary_action_label: __("Remove Colors"),
                secondary_action: function() {
                    cur_page.page.page.fields_dict.coloring.value = null;
                    cur_page.page.page.fields_dict.coloring.onchange();
                    dialog.hide();
                },
            });
            // if()
            let color_params = this.parse_color_url();
            if (color_params) {
                dialog.fields_dict["color_field"].value = color_params[0];
                dialog.fields_dict["color_condition"].value = color_params[1];
                dialog.fields_dict["condition_value"].value = color_params[2];
                dialog.fields_dict["color"].value = color_params.slice(3, 5).join("");
                dialog.refresh();
            };
            dialog.show();

        };
        cur_page.page.page.fields_dict.coloring.refresh();

        let currencyInfo = frappe.call({
            method: 'tabulator.overrides.query_report.get_currency_info',
            async: false,
            callback: function(response) {
                return response.message;
            }
        });

        this.tabulator_cols.forEach((col) => {
            if (!col.formatter) {
                this.set_column_formatter(col, currencyInfo);
            }
        });

    }

    render_tabulator(data) {
        let opts = {
            // height: `${400}px`,
            placeholder: "No data to display",
            layout: "fitDataFill",
            movableColumns: true,

        }
        let group_by = "";
        let color_field = "";
        let coloring_values = this.get_filter_values().coloring;
        if (coloring_values) {
            coloring_values = coloring_values.split(/(<|>|<=|>=|==|!=|#)/);
        }

        let cols_frozen = false;

        this.tabulator_cols.forEach((t) => {
            if (this.get_filter_values().freeze && !cols_frozen) {
                if (t.label === this.get_filter_values().freeze) {
                    cols_frozen = true;
                };
                t.frozen = true;
            };
            if (t.label === this.get_filter_values().grouping) {
                group_by = t.fieldname;
            };
            if (coloring_values && t.label === coloring_values[0]) {
                color_field = t.fieldname;
            };
        });
        if (this.report_settings.tree) {
            data = this.format_to_tree(data);
            opts.dataTree = true;
            opts.dataTreeStartExpanded = true;
            opts.dataTreeChildIndent = 20;
        };

        opts.groupBy = group_by;


        // render color if conditions applied
        if (coloring_values) {
            opts.rowFormatter = function(row) {
                var data = row.getData();
                if (!data[color_field]) {
                    data[color_field] = `""`;
                }
                if (typeof data[color_field] == "number") {
                    if (eval(data[color_field] + coloring_values.slice(1, 3).join(""))) {
                        row.getElement().style.backgroundColor = coloring_values.slice(3, 5).join("");
                    }
                } else {
                    if (eval("`" + data[color_field] + "`" + coloring_values.slice(1, 3).join("`") + "`")) {
                        row.getElement().style.backgroundColor = coloring_values.slice(3, 5).join("");
                    }
                }
            };
        } else {
            opts.rowFormatter = function(row) {
                row.getElement().style.backgroundColor = '';
            };
        }

        this.tabulator = new Tabulator(this.$report[0], opts);
        this.tabulator.setColumns(this.tabulator_cols);
        this.tabulator.setData(data || []);
    }

    parse_color_url() {
        let url = this.get_url_with_filters();
        const urlParams = new URL(url).searchParams;
        let color_params = urlParams.get('coloring');
        if (color_params && color_params.length) {
            color_params = color_params.split(/(<|>|<=|>=|==|!=|#)/);
        }
        return color_params;
    }

    format_to_tree(data) {
        let tree = [];
        data.forEach((e) => {
            if (e.indent === 0) {
                e._children = [];
                tree.push(e);
            } else if (e.indent >= 1) {
                tree[tree.length - 1]._children.push(e);
            } else {
                tree.push(e);
            }
        })
        return tree;
    }

    render_datatable() {
        let data = this.data;
        var headerContextMenu = [{
            label: "Hide Column",
            action: function(e, column) {
                column.hide();
            }
        }, ]
        let new_columns = this.columns.filter((col) => !col.hidden)
        let columns = [{
            formatter: "rowSelection",
            titleFormatter: "rowSelection",
            hozAlign: "center",
            headerSort: false,
            cellClick: function(e, cell) {
                cell.getRow().toggleSelect();
            }
        }]
        columns.push({ formatter: "rownum", hozAlign: "center", width: 40 });
        let currencyInfo = null;
        frappe.call({
            method: 'tabulator.overrides.query_report.get_currency_info',
            async: false,
            callback: function(response) {
                currencyInfo = response.message;
            }
        });

        if (currencyInfo) {
            // Loop through the columns and update based on the fetched currency info
            for (let i = 0; i < new_columns.length; i++) {
                new_columns[i].headerContextMenu = headerContextMenu;
                
                if (!["Data", "data", "date", "Date", "Dynamic Link", "dynamic link", "Link", "link", "", "Small Text"].includes(new_columns[i].fieldtype) && new_columns[i].hasOwnProperty("fieldtype")) {
                    new_columns[i].bottomCalc = "sum";
                    new_columns[i].bottomCalcFormatter = 'money';
                    
                    if (["Currency", "currency"].includes(new_columns[i].fieldtype)) {
                        new_columns[i].bottomCalcFormatterParams = {
                            symbol: currencyInfo.symbol,
                            thousand: currencyInfo.number_format.includes(',') ? ',' : '.',
                            precision: 2,
                        };
                    } else {
                        new_columns[i].bottomCalcParams = { precision: 2 };
                    }
        
                    new_columns[i].hozAlign = "right";
                    new_columns[i].headerHozAlign = "right";
                }
        
                columns.push(new_columns[i]);
                // new_columns.push
                // localStorage.setItem('new_columns', JSON.stringify(new_columns));


            }
        }


        if (this.raw_data.add_total_row && !this.report_settings.tree) {
            data = data.slice();
            data.splice(-1, 1);
        }

        this.$report.show();
        if (
            this.datatable &&
            this.datatable.options &&
            this.datatable.options.showTotalRow === this.raw_data.add_total_row
        ) {
            this.datatable.options.treeView = this.tree_report;
            this.add_filter_options(columns);
            this.render_tabulator(data);
        } else {
            let datatable_options = {
                columns: columns,
                data: data,

                inlineFilters: true,
                language: frappe.boot.lang,
                translations: frappe.utils.datatable.get_translations(),
                treeView: this.tree_report,
                layout: "fixed",
                cellHeight: 33,
                showTotalRow: this.raw_data.add_total_row && !this.report_settings.tree,
                direction: frappe.utils.is_rtl() ? "rtl" : "ltr",
                hooks: {
                    columnTotal: frappe.utils.report_column_total,
                },
            };

            if (this.report_settings.get_datatable_options) {
                datatable_options = this.report_settings.get_datatable_options(datatable_options);
            }
            this.datatable = new DataTable(this.$report[0], datatable_options);

            this.add_filter_options(columns);
            this.render_tabulator(data);
        }

        if (typeof this.report_settings.initial_depth == "number") {
            this.datatable.rowmanager.setTreeDepth(this.report_settings.initial_depth);
        }
        if (this.report_settings.after_datatable_render) {
            this.report_settings.after_datatable_render(this.datatable);
        }
    }


    set_column_formatter(col, currencyInfo) {
        //formating the columns for link, float, currency
        if (!col.fieldtype || col.fieldtype == "") {
            if (
                this.data &&
                this.data.result &&
                this.data.result.length &&
                is_html(this.data.result[0][col.fieldname])
            ) {
                col.formatter = "html";
            }
        } else if (col.fieldtype == "Link") {
            let formated_doc = (col.options).replace(/\s+/g, '-').toLowerCase();
            col.formatter = function(cell) {
                if ((typeof cell) != "number") {
                    if (cell.getValue() != null) {
                        return String(`<a href='/app/${formated_doc
					}/${cell.getValue()}'>${cell.getValue()}</a>`);
                    } else {
                        return "";
                    }
                } else {
                    return frappe.query_report.data[cell][col.fieldname];
                }
            };
        } else if ((col.fieldtype).toLowerCase() == "dynamic link") {
            col.formatter = function(cell) {
                if ((typeof cell) != "number") {
                    if (cell.getValue() != null) {
                        let doctype = (cell._cell.row.data[col.options] || cell._cell.column.definition.name)
                        let formated_doctype = (doctype).replace(/\s+/g, '-').toLowerCase();
                        return String(`<a href='/app/${formated_doctype
					}/${cell.getValue()}'>${cell.getValue()}</a>`);
                    } else {
                        return "";
                    }
                } else {
                    return frappe.query_report.data[cell][col.fieldname];
                }
            };
        } else if ((col.fieldtype).toLowerCase() == "currency" || (col.fieldtype).toLowerCase() == "float") {
            col.formatter = function(cell) {

                if ((col.fieldtype).toLowerCase() == "float") {
                    let val = ((typeof cell) == "number" ? frappe.query_report.data[cell][col.fieldname] : parseFloat(cell.getValue()).toFixed(2))
                    if (val != 'NaN') {
                        return val
                    } else {
                        return
                    }
                } else {
                    let value = ((typeof cell) == "number" ? frappe.query_report.data[cell][col.fieldname] : cell.getValue());

                    let formattedValue = formatCurrency(value, currencyInfo);
                    return formattedValue;
                }

            }
        } else if ((col.fieldtype).toLowerCase() == "date") {
            col.formatter = function(cell) {
                if ((typeof cell) != "number") {
                    if (cell.getValue() != null) {
                        const inputDate = cell.getValue();
                        const formattedDate = formatDate(inputDate);
                        return formattedDate;
                    } else {
                        return "";
                    }
                } else {
                    return frappe.query_report.data[cell][col.fieldname];
                }
            }
        }
    }

}

function formatCurrency(value, currencyInfo) {
    let currencySymbol = currencyInfo.responseJSON.message.symbol;
    let numberFormat = currencyInfo.responseJSON.message.number_format;
    var currencySymbolToCode = {
        '$': 'en-US', // US Dollar
        '€': 'en-EUR', // Euro
        '₹': 'en-IN', // Indian Rupee
        // Add more symbols and codes as needed
    };
    let currencyCode = currencySymbolToCode[currencySymbol]

    if (value != undefined && value != 0) {
        return currencySymbol + " " + value.toLocaleString(currencyCode);
    } else {
        return
    }
}

function formatDate(inputDate) {
    let day, month, year;

    // Check if the input date is in "dd-mm-yyyy" format
    if (/^\d{2}-\d{2}-\d{4}$/.test(inputDate)) {
        const parts = inputDate.split('-');
        day = parts[0];
        month = parts[1];
        year = parts[2];
    } else {
        // Parse the input date into a Date object
        const date = new Date(inputDate);

        // Extract day, month, and year
        day = String(date.getDate()).padStart(2, '0');
        month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
        year = date.getFullYear();
    }

    // Format as "dd-mm-yyyy"
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
}


// TODO: move to utils
function is_html(str) {
    return basic_html.test(str) || full_html.test(str);
}

const HTML_TAGS = [
    "a",
    "abbr",
    "address",
    "area",
    "article",
    "aside",
    "audio",
    "b",
    "base",
    "bdi",
    "bdo",
    "blockquote",
    "body",
    "br",
    "button",
    "canvas",
    "caption",
    "cite",
    "code",
    "col",
    "colgroup",
    "data",
    "datalist",
    "dd",
    "del",
    "details",
    "dfn",
    "dialog",
    "div",
    "dl",
    "dt",
    "em",
    "embed",
    "fieldset",
    "figcaption",
    "figure",
    "footer",
    "form",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "head",
    "header",
    "hgroup",
    "hr",
    "html",
    "i",
    "iframe",
    "img",
    "input",
    "ins",
    "kbd",
    "label",
    "legend",
    "li",
    "link",
    "main",
    "map",
    "mark",
    "math",
    "menu",
    "menuitem",
    "meta",
    "meter",
    "nav",
    "noscript",
    "object",
    "ol",
    "optgroup",
    "option",
    "output",
    "p",
    "param",
    "picture",
    "pre",
    "progress",
    "q",
    "rb",
    "rp",
    "rt",
    "rtc",
    "ruby",
    "s",
    "samp",
    "script",
    "section",
    "select",
    "slot",
    "small",
    "source",
    "span",
    "strong",
    "style",
    "sub",
    "summary",
    "sup",
    "svg",
    "table",
    "tbody",
    "td",
    "template",
    "textarea",
    "tfoot",
    "th",
    "thead",
    "time",
    "title",
    "tr",
    "track",
    "u",
    "ul",
    "var",
    "video",
    "wbr",
];

const basic_html = /\s?<!doctype html>|(<html\b[^>]*>|<body\b[^>]*>|<x-[^>]+>)+/i;
const full_html = new RegExp(
    HTML_TAGS.map((tag) => `<${tag}\\b[^>]*>`).join("|"),
    "i"
);